<template>
  <v-app>
    <v-content>
      <div
        style="
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div style="display: flex; justify-content: center">
          <img src="/logo2.jpg" style="width: 200px" />
        </div>
        <div class="mt-10" style="margin-top: 30px; max-width: 480px">
          <div style="display: flex; justify-content: center">
            <h1>Regístrate</h1>
          </div>
          <v-card class="pa-4">
            <ValidationObserver v-slot="{ invalid }" ref="form">
              <v-row no-guters>
                <v-col cols="12" class="mx-0">
                  <v-btn-toggle
                    v-model="form.type"
                    mandatory
                    style="width: 100%"
                  >
                    <v-btn
                      :color="form.type == 'to-lend' ? 'primary' : ''"
                      value="to-lend"
                      style="width: 50%"
                    >
                      Quiero un préstamo
                    </v-btn>

                    <v-btn
                      :color="form.type == 'to-invest' ? 'primary' : ''"
                      value="to-invest"
                      style="width: 50%"
                    >
                      Quiero prestar
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12">
                  <ng-text
                    v-model="form.name"
                    rules="required"
                    label="Nombres Completos"
                  ></ng-text>
                </v-col>
                <v-col cols="12">
                  <ng-text
                    v-model="form.surname"
                    rules="required"
                    label="Apellidos Completos"
                  ></ng-text>
                </v-col>

                <v-col cols="12" class="d-flex">
                  <vue-country-code
                    @onSelect="onSelect"
                    :preferredCountries="['co', 'us', 'es', 'mx', 'ec']"
                  >
                  </vue-country-code>
                  <ng-phone
                    v-model="form.mobile"
                    rules="required"
                    label="Celular"
                  ></ng-phone>
                </v-col>

                <v-col cols="12">
                  <ng-email
                    v-model="form.email"
                    rules="required|email"
                    label="Email"
                    max="10"
                  ></ng-email>
                </v-col>
                <v-col cols="12">
                  <ng-passwd
                    v-model="form.password"
                    rules="required"
                    label="Contraseña"
                    type="password"
                  ></ng-passwd>
                  <ul class="mt-2">
                    <li>Contiene al menos 8 caracteres</li>
                    <li>Contiene al menos un caracter numérico (0-9)</li>
                    <li>Contiene al menos un caracter especial (*?$)</li>
                  </ul>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    v-model="form.policies"
                    hide-details
                    :rules="['required']"
                  >
                    <template v-slot:label>
                      <p>
                        Estoy de acuerdo con los
                        <a
                          target="_blank"
                          class="chakra-link css-1n193ak"
                          href="https://konetzi.com/terminos-y-condiciones"
                          >Términos y Condiciones</a
                        >
                        y con el tratamiento de mis datos según el
                        <a
                          target="_blank"
                          class="chakra-link css-1n193ak"
                          href="https://konetzi.com/politica-proteccion-datos"
                          >Aviso de Privacidad</a
                        >.
                        Conoce la 
                        <a
                          target="_blank"
                          class="chakra-link css-1n193ak"
                          href="https://konetzi.com/pol%C3%ADtica-sarlaft"
                          >POLÍTICA DE SARLAFT</a
                        >
                      </p>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" style="display: flex; justify-content: center">
                  <v-btn
                    @click="validate"
                    color="primary"
                    :disabled="invalid || !form.policies"
                    block
                    >Continuar</v-btn
                  >
                </v-col>

                <v-col class="mt-4">
                  <div style="display: flex; justify-content: center">
                    <!-- to="/auth/terms" -->
                    <p>
                      Si ya tienes una cuenta
                      <a class="chakra-link css-1n193ak" href="/"
                        >inicia sesión aquí</a
                      >
                    </p>
                  </div>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-card>
        </div>
      </div>
    </v-content>

    <v-dialog v-model="modal" max-width="420">
      <v-card class="pa-2">
        <v-card-title>Verifica tu correo</v-card-title>
        <v-card-text>
          <p>
            Te hemos enviado un correo electrónico a
            <b>{{ form.email }}</b> para que verifiques tu cuenta.
          </p>
          <p>Si no lo encuentras, revisa tu bandeja de spam.</p>

          <div>
            <v-otp-input
              v-model="otp"
              :disabled="loading"
              @finish="onFinish"
            ></v-otp-input>
            <div class="d-flex justify-center"> <v-btn color="primary" :disabled="!finishOtp">Validar Código</v-btn></div>
           
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="modal = false">Cerrar</v-btn>
          <v-btn @click="sendEmail" color="info">Reenviar correo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
  
  <script>
import NgPasswd from "@/components/inputs/NgPasswd.vue";
import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue";
export default {
  name: "user-registration-form",
  components: {
    NgPasswd,
    NgEmail,
    NgText,
    NgPhone,
  },
  data: () => ({
    form: { type: 'to-lend' }, // to-invest
    otp: '',
    finishOtp: false,
    loading: false,
    modal: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => /.+@.+\..+/.test(value) || "Invalid email.",
    },
  }),

  computed: {},
  mounted() {
    if(this.$route.params.type){
      this.form.type = this.$route.params.type;
    }
  },
  methods: {
    onFinish() {
        this.finishOtp = true;
        console.log(this.otp);
    },
    onSelect(evt) {
      this.form.country = evt;
    },
    validate() {
      this.modal = true;
    },
    sendEmail() {
      this.$http.post().then((response) => {
        if (response.success) {
        }
      });
    },
    register() {
      this.$http.post().then((response) => {
        if (response.success) {
        }
      });
    },
  },
};
</script>
  